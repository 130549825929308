import { FC, useEffect, useRef, useState } from 'react';
import { IAssetsItem } from 'redux/reducers/tradingPairs/types';
import { IDCDropdown } from './types';

// ==================================:
const CDropdown: FC<IDCDropdown> = (props) => {
	const { value, options, placeholder = '', onChange, currentClass, onBloor, disabled } = props;

	const [open, setOpen] = useState(false);
	const node = useRef<HTMLDivElement | null>(null);

	const handleClick = (event: MouseEvent): boolean | undefined => {
		if (node?.current?.contains(event.target as Node)) {
			return false;
		}

		setOpen(false);
		return true;
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClick);

		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	const handleChange = (selectedValue: IAssetsItem): void => {
		if (disabled) {
			return;
		}
		onChange(selectedValue);
		setOpen(false);
	};

	const handleSelectToggel = (): void => {
		setOpen(!open);
	};

	return (
		<div
			className={`select select--coin-list select--height-58 ${currentClass || ''}${
				open ? 'active' : ''
			} ${disabled ? 'select--disabled' : ''}`}
			ref={node}
		>
			<button
				type="button"
				className="select__current"
				onClick={handleSelectToggel}
				onBlur={onBloor}
				disabled={disabled}
			>
				{value ? (
					<>
						<span className="select-coin">
							<img width="30" height="30" src={value?.img_path} alt="" />
						</span>
						<span className="select__current-text">
							{value.name}
							<span className="select__current-currency">&nbsp;{value.code.toUpperCase()}</span>
						</span>
					</>
				) : (
					<span className="select-coin grey-text">{placeholder}</span>
				)}
				<span className="select__current-arrow">
					<svg
						className="stroke"
						width="8"
						height="5"
						viewBox="0 0 8 5"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 1L4 4L7 1" stroke="#424242" strokeLinecap="square" />
					</svg>
				</span>
			</button>
			{open && options && (
				<div className="select__drop">
					<div className="select__drop-scroll">
						<div className="select__drop-item">
							{options.length ? (
								<ul>
									{options?.map((opt: IAssetsItem) => {
										return (
											<li key={opt?.id}>
												<button type="button" onClick={() => handleChange(opt)} disabled={disabled}>
													<span className="select-coin">
														<img width="30" height="30" src={opt?.img_path} alt="" />
													</span>
													<span className="select__current-text">
														{opt.name}
														<span className="select__current-currency">
															&nbsp;{opt.code.toUpperCase()}
														</span>
													</span>
												</button>
											</li>
										);
									})}
								</ul>
							) : (
								<span className="select__drop-link select__drop-link_no--hover">
									<span className="select__drop-text">No payments method</span>
								</span>
							)}
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default CDropdown;
