/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterParams } from 'redux/reducers/walletManagement/selectors';
import queryString from 'query-string';
import SearchBar from 'components/SearchBar';
import { geHotColdTransactionsRequest } from 'redux/reducers/walletManagement/reducer';
import usePermission from 'services/hooks/usePermission';
import { permissions } from 'services/constants/permissions';
import { SpotTradesFilter } from './filters/SpotTradesFilter';
import { WalletsFilter } from './filters/WalletsFilter';
import { KrakenBalanceFilter } from './filters/KrakenBalanceFilter';
import { ExternalWalletHistoryFilter } from './filters/ExternalWalletHistoryFilter';
import {
	USER_SEARCH_ARRAY,
	USER_SEARCH_TEXT_ARRAY,
	USER_STATUS_ARRAY,
	USER_STATUS_TEXT_ARRAY,
	USER_TYPE_ARRAY,
	USER_TYPE_TEXT_ARRAY,
	USER_LEVEL_ARRAY,
} from './constants';
import { BotLiquidity } from './BotLiquidity';
import { Transactions } from './Transactions';
import { Wallet } from './Wallet';
import { KrakenBalance } from './KrakenBalance';
import { BankBalance } from './BankBalances';
import { ExternalWallet } from './ExternalWallet';
import CreateExternalWalletPopup from './ExternalWallet/CreatePopup';
import { ExternalWalletHistory } from './ExternalWalletHistory';

// simport TransactionSearch from './Transactions/TransactionSearch';
import ExternalWalletSearch from './ExternalWallet/ExternalWalletSearch';

export const WalletManagement: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [createExternalWalletOpen, setCreateExternalWalletOpen] = useState(false);

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const filterParams = useSelector(getFilterParams);
	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : 'id');
	const { start_date: startDate, end_date: endDate } = filterParams || {};

	const [dropdownArray, setDropdownArray] = useState<{
		dropdownArray: string[];
		dropdownTextArray: string[];
	}>({ dropdownArray: ['all'], dropdownTextArray: ['all'] });
	const [typeSearchDropdown, setTypeSearchDropdown] = useState(false);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	const { checkEditable } = usePermission();

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const handleSearch = (txt: string) => {
		console.log(startDate);
		const params = {
			current_page: 1,
			search_value: txt,
			search_field: searchField,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};

		if (txt.length >= 1) {
			dispatch(geHotColdTransactionsRequest(params));
		} else if (startDate && endDate) {
			dispatch(
				geHotColdTransactionsRequest({
					current_page: 1,
					start_date: startDate || undefined,
					end_date: endDate || undefined,
				}),
			);
		} else {
			dispatch(geHotColdTransactionsRequest({ current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchField}`
					: `?page=${String(1)}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};
	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const searchFieldHandler = (txt: string) => {
		if (txt === 'status') {
			setDropdownArray({
				dropdownArray: USER_STATUS_ARRAY,
				dropdownTextArray: USER_STATUS_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}
		if (txt === 'type') {
			setDropdownArray({
				dropdownArray: USER_TYPE_ARRAY,
				dropdownTextArray: USER_TYPE_TEXT_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		if (txt === 'level') {
			setDropdownArray({
				dropdownArray: USER_LEVEL_ARRAY,
				dropdownTextArray: USER_LEVEL_ARRAY,
			});
			setSearchField(txt);
			setTypeSearchDropdown(true);
			return;
		}

		setSearchField(txt);
		setTypeSearchDropdown(false);
	};

	return (
		<>
			<div className="title-block">
				<p className="title">Wallet Management</p>
			</div>
			<div>
				{tabIndex === 0 && (
					<div className="count-label count-label--filter-type">
						<WalletsFilter />
					</div>
				)}
				{tabIndex === 1 && (
					<div className="count-label count-label--filter-type">
						<SearchBar
							setDebouncedTerm={setDebouncedTerm}
							debouncedTerm={debouncedTerm}
							searchFieldArray={USER_SEARCH_ARRAY}
							searchField={searchField}
							setSearchField={searchFieldHandler}
							handleClearSearch={handleClearSearch}
							searchFieldTextArray={USER_SEARCH_TEXT_ARRAY}
							typeSearch={typeSearchDropdown ? 'dropdown' : undefined}
							dropdownArray={dropdownArray.dropdownArray}
							dropdownTextArray={dropdownArray.dropdownTextArray}
						/>
						<SpotTradesFilter />
					</div>
				)}
				{tabIndex === 3 && (
					<div className="count-label count-label--filter-type">
						<KrakenBalanceFilter />
					</div>
				)}
				{tabIndex === 5 && (
					<div className="search-group">
						<div className="input input--search">
							<ExternalWalletSearch />
						</div>
						<button
							type="button"
							className="admin-managment-btn"
							disabled={!checkEditable(permissions.MANAGE_WALLETS)}
							onClick={() => setCreateExternalWalletOpen(true)}
						>
							<span className="admin-managment-btn__text">Add new wallet</span>
							<span className="plus-icon plus-icon--action icon-plus-icon" />
						</button>
					</div>
				)}
				{tabIndex === 6 && (
					<div className="count-label count-label--filter-type">
						<ExternalWalletHistoryFilter />
					</div>
				)}
			</div>

			{createExternalWalletOpen && (
				<CreateExternalWalletPopup onClose={() => setCreateExternalWalletOpen(false)} />
			)}

			<Tabs
				className="settings-tabs"
				selectedIndex={tabIndex}
				onSelect={(index) => setTabIndex(index)}
			>
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Wallet</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Transactions</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Bot Liquidity</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Kraken balance</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Pegged Liquidity</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">External Wallet</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">External Wallet History</span>
					</Tab>
				</TabList>
				<TabPanel>
					<Wallet />
				</TabPanel>
				<TabPanel>
					<Transactions />
				</TabPanel>
				<TabPanel>
					<BotLiquidity />
				</TabPanel>
				<TabPanel>
					<KrakenBalance />
				</TabPanel>
				<TabPanel>
					<BankBalance />
				</TabPanel>
				<TabPanel>
					<ExternalWallet />
				</TabPanel>
				<TabPanel>
					<ExternalWalletHistory />
				</TabPanel>
			</Tabs>
		</>
	);
};
