import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	addExternalMakerPairRequest,
	deleteExternalPairRequest,
	deleteExternalPairSuccess,
	getExternalMakerRequest,
	getExternalMakerRequestSuccess,
	getOrderHistoryRequest,
	getOrderHistoryRequestSuccess,
	patchExternalMakerUppdateStatusRequest,
	patchExternalMakerUppdateStatusRequestSuccess,
} from './reducer';
import {
	IAddExternalMakerPairPayload,
	IDeleteExternalPairPayload,
	IExternalMakerPageRequestPayload,
	IExternalMakerResponsePayload,
	IExternalMakerUpdatePayload,
	IExternalMakerUpdateResponse,
	IOrderHistoryResponsePayload,
	IOrderHistoryPageRequestPayload,
} from './types';
// =============================================================:
// =============================================================:
function* externalMakerRequestWorker(action: PayloadAction<IExternalMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IExternalMakerResponsePayload = yield call(
			api.externalMaker.getExternalMakerElements,
			payload,
		);
		yield put(getExternalMakerRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* externalMakerUppdateWorker(body: PayloadAction<IExternalMakerUpdatePayload>) {
	const { payload } = body;
	try {
		const response: IExternalMakerUpdateResponse = yield call(
			api.externalMaker.patchExternalMakerElement,
			payload,
		);

		yield put(patchExternalMakerUppdateStatusRequestSuccess(response));
		yield put(getExternalMakerRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* addExternalMakerPairWorker({ payload }: PayloadAction<IAddExternalMakerPairPayload>) {
	const { apiParams, onSuccess } = payload;
	try {
		yield put(showLoading());
		yield call(api.externalMaker.addExternalMakerPair, apiParams);

		onSuccess();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* deleteExternalPairWorker(action: PayloadAction<IDeleteExternalPairPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.externalMaker.deleteExternalPair, payload);
		yield put(deleteExternalPairSuccess());

		notificationContainer('Pair has been deleted!', 'success');

		const params = { current_page: Number(payload.page) || 1 };
		yield put(getExternalMakerRequest(params));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* orderHistoryRequestWorker(action: PayloadAction<IOrderHistoryPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IOrderHistoryResponsePayload = yield call(
			api.externalMaker.getOrderHistory,
			payload,
		);
		if (payload.export === 1) {
			notificationContainer('Order history successfully exported, check your mailbox!', 'info');
		} else yield put(getOrderHistoryRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* externalMakerSaga() {
	yield takeEvery(getExternalMakerRequest, externalMakerRequestWorker);
	yield takeEvery(patchExternalMakerUppdateStatusRequest, externalMakerUppdateWorker);
	yield takeEvery(addExternalMakerPairRequest, addExternalMakerPairWorker);
	yield takeEvery(deleteExternalPairRequest, deleteExternalPairWorker);
	yield takeEvery(getOrderHistoryRequest, orderHistoryRequestWorker);
}
