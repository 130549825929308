/* eslint-disable react/no-array-index-key */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { IOrderHistoryRequestPayload } from 'redux/reducers/walletManagement/types';
import { getOrderHistory, getOrderHistoryLoader } from 'redux/reducers/externalMaker/selectors';
import { getOrderHistoryRequest } from 'redux/reducers/externalMaker/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { OrderHistoryItem } from './OrderHistoryItem';
import { IOrderHistory } from './types';

export interface IMockDataItem {
	provider: string;
	date: string;
	pair: string;
	typeOfOrder: string;
	side: string;
	priseRequested: string;
	excutedPrice: string;
	filled: number;
	status: string;
	fee: string;
	total: string;
}

export interface IMockData {
	data: IMockDataItem[];
}

const mockData: IMockData = {
	data: [
		{
			provider: 'Kraken',
			date: '2021-11-03 16:47:53',
			pair: 'BTC/USDT',
			typeOfOrder: 'Market order',
			side: 'Buy',
			priseRequested: '0.7502735 ETH',
			excutedPrice: '0.7502735 ETH',
			filled: 100,
			status: 'Filled',
			fee: '0.7502735 ETH',
			total: '0.7502735 ETH',
		},
		{
			provider: 'Kraken',
			date: '2021-11-03 16:47:53',
			pair: 'BTC/USDT',
			typeOfOrder: 'Market order',
			side: 'Buy',
			priseRequested: '0.7502735 ETH',
			excutedPrice: '0.7502735 ETH',
			filled: 100,
			status: 'Filled',
			fee: '0.7502735 ETH',
			total: '0.7502735 ETH',
		},
		{
			provider: 'Kraken',
			date: '2021-11-03 16:47:53',
			pair: 'BTC/USDT',
			typeOfOrder: 'Market order',
			side: 'Buy',
			priseRequested: '0.7502735 ETH',
			excutedPrice: '0.7502735 ETH',
			filled: 100,
			status: 'Filled',
			fee: '0.7502735 ETH',
			total: '0.7502735 ETH',
		},
	],
};

export const OrderHistory: FC<IOrderHistory> = ({ filterParams }) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search, pathname } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const { startDate, endDate } = filterParams || {};

	const orderHistoryData = useSelector(getOrderHistory);
	const orderHistoryLoader = useSelector(getOrderHistoryLoader);

	useEffect(() => {
		dispatch(
			getOrderHistoryRequest({
				start_date: startDate || undefined,
				end_date: endDate || undefined,
			}),
		);
	}, [dispatch, endDate, startDate]);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params: IOrderHistoryRequestPayload = {
			current_page: pageNumber || 1,
			start_date: startDate || undefined,
			end_date: endDate || undefined,
		};
		history.push({
			pathname,
			search: searchParams,
		});

		dispatch(getOrderHistoryRequest(params));
	};

	return (
		<>
			<div className="content-block content-block--walletman">
				<div>
					<div className="table-block table-block--mt-0 table-block--border-none">
						<div className="table-wrapper">
							<div className="table table--external-history">
								<div className="table-header">
									<div className="tr">
										<div className="td">
											<div className="td-name">
												<p>Provider</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Date</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Pair</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Original</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Type of order</p>
											</div>
										</div>
										<div className="td">
											<div className="td-name">
												<p>Side</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Price requested</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Executed price</p>
											</div>
										</div>
										<div className="td ">
											<div className="td-name">
												<p>GEL Amount</p>
											</div>
										</div>
										<div className="td ">
											<div className="td-name">
												<p>GEL Rate</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Filled</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Status</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Fee</p>
											</div>
										</div>
										<div className="td td">
											<div className="td-name">
												<p>Total</p>
											</div>
										</div>
									</div>
								</div>

								{!orderHistoryData?.data.length && !orderHistoryLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No wallets found...</p>
									</div>
								)}

								{orderHistoryLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{orderHistoryData?.data?.map((item, index) => (
											<OrderHistoryItem key={index} data={item} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{(orderHistoryData?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={orderHistoryData?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};
