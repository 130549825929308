import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IExternalMakerApi } from './types';

// ==========================================:
export const externalMaker: IExternalMakerApi = {
	getExternalMakerElements: (params) =>
		http
			.get(endpoint.externalMaker.EXTERNAL_MAKER_PAIRS, { params })
			.then((response) => response.data),
	patchExternalMakerElement: (params) =>
		http.patch(endpoint.externalMaker.EXTERNAL_MAKER_PAIRS_UPDATE(params.id), params.body),
	addExternalMakerPair: (params) =>
		http
			.post(endpoint.externalMaker.ADD_EXTERNAL_MAKER_PAIR, params)
			.then((response) => response.data),
	deleteExternalPair: (payload) =>
		http
			.delete(endpoint.externalMaker.DELETE_EXTERNAL_PAIR(payload.pair_id))
			.then((response) => response.data),
	getOrderHistory: (params) =>
		http.get(endpoint.externalMaker.ORDER_HISTORY, { params }).then((response) => response.data),
};
