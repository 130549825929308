/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetsList } from 'redux/reducers/tradingPairs/selectors';
import { IUserItem } from 'redux/reducers/userManagement/types';
import { getUsersRequest, setUsers } from 'redux/reducers/userManagement/reducer';
import { getUsersList, getUsersIsLoad } from 'redux/reducers/userManagement/selectors';
import SearchBar from 'ui/SearchBarPopup';
import CDropdown from 'ui/CDropdown';
import { IAssetsItem } from 'redux/reducers/tradingPairs/types';
import {
	TYPE_TRANSACTIONS_FIAT_SEARCH_POPUP_ARRAY,
	TYPE_TRANSACTIONS_FIAT_SEARCH_POPUP_TEXT_ARRAY,
} from 'redux/reducers/transactions/constants';
import { createTransaction, createTransactionCrypto } from 'redux/reducers/transactions/reducer';
import {
	ICreateTransactionBankAccountRequest,
	ICreateTransactionCryptoPayload,
} from 'redux/reducers/transactions/types';
import { EMAIL_REGEX } from 'services/constants/regex';
import { IRemoveWhitelistingIPModalProps } from './types';
import { ConfirmPopup } from '../ConfirmPopup/ConfirmPopup';

// ================================================:
export const CreateTransaction: FC<IRemoveWhitelistingIPModalProps> = ({
	openModal,
	closeModal,
	onSuccessed,
	type,
}) => {
	const assets = useSelector(getAssetsList);
	const usersList = useSelector(getUsersList);
	const usersLoader = useSelector(getUsersIsLoad);
	const dispatch = useDispatch();
	const [username, setUsername] = useState('');
	const assetFiat = (assets && assets.filter((item) => item.type === 'fiat')) || [];
	const [selectWallet, setSelectWallet] = useState<IAssetsItem | null>(null);
	const [term, setTerm] = useState('');
	const [confirmModal, setConfirmModal] = useState(false);
	const [amount, setAmount] = useState('');
	const [comment, setComment] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);
	const [searchFieldArray, setSearchFieldArray] = useState(
		TYPE_TRANSACTIONS_FIAT_SEARCH_POPUP_ARRAY,
	);
	const [searchFieldTextArray, setSearchFieldTextArray] = useState(
		TYPE_TRANSACTIONS_FIAT_SEARCH_POPUP_TEXT_ARRAY,
	);

	const [searchField, setSearchField] = useState(searchFieldArray[0]);
	const [timeoutLoader, setTimeoutLoader] = useState(false);
	const [withMint, setWithMint] = useState(true);
	const [showComment, setShowComment] = useState(true);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};
	const handleSearch = (txt: string) => {
		const params = {
			search_value: txt,
			search_field: searchField,
		};
		if (txt.length >= 1) {
			setTimeoutLoader(false);
			dispatch(getUsersRequest(params));
		}
	};

	const disable = !(amount && usersList?.users.length && selectWallet);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 500);
		return () => {
			setTimeoutLoader(true);
			clearTimeout(timer);
		};
	}, [debouncedTerm]);

	useEffect(() => {
		setUsername('');
		dispatch(setUsers(null));
	}, [searchField]);

	useEffect(() => {
		if (usersList) {
			setUsername(
				usersList?.users[0]?.username === ' ' ? 'no name' : usersList?.users[0]?.username,
			);
		}
	}, [usersList]);

	useEffect(() => {
		if (term) {
			if (searchField === 'email' && !term.match(EMAIL_REGEX)) {
				return;
			}
			handleSearch(term);
		}
	}, [term, searchField]);

	useEffect(() => {
		if (type === 'crypto' && (!selectWallet || selectWallet.type !== type)) {
			const usdtAsset = assets?.find((item) => item.code === 'usdt');
			usdtAsset && setSelectWallet(usdtAsset);
		}
		if (type === 'fiat' && selectWallet) {
			if (!assetFiat?.find((item) => item.id === selectWallet.id)) {
				setSelectWallet(null);
			}
		}
	}, [type, selectWallet, assets]);

	useEffect(() => {
		return () => {
			setAmount('');
			setComment('');
			setDebouncedTerm('');
			setTerm('');
			setUsername('');
		};
	}, [openModal]);

	const handleWalletSelect = (value: IAssetsItem): void => {
		setSelectWallet(value);
	};

	const handleClick = () => {
		const payload = {
			amount: Number(amount),
			asset_id: Number(selectWallet?.id) || undefined,
			user_id: Number(usersList?.users[0].id) || undefined,
			manual_deposit_comment: comment || undefined,
		};
		const onSuccess = () => {
			closeModal();
			setConfirmModal(false);
			onSuccessed();
		};
		if (amount) {
			if (type === 'crypto') {
				const params: ICreateTransactionCryptoPayload = {
					payload: {
						...payload,
					},
					onSuccess,
					onError: () => {},
				};
				dispatch(createTransactionCrypto(params));
			} else {
				const params: ICreateTransactionBankAccountRequest = {
					payload: {
						...payload,
						with_mint: withMint ? 1 : 0,
					},
					onSuccess,
					onError: () => {},
				};
				dispatch(createTransaction(params));
			}
		}
	};

	const searchUserError =
		!!debouncedTerm && !usersLoader && !timeoutLoader ? !usersList?.users.length : false;

	return (
		<Popup open={openModal} closeOnDocumentClick onClose={closeModal} closeOnEscape>
			{confirmModal ? (
				<ConfirmPopup
					user={usersList?.users[0]}
					amount={amount}
					onSubmit={handleClick}
					onBack={() => setConfirmModal(false)}
				/>
			) : (
				<div className="popup-window">
					<div className="popup-window__inside">
						<div className="popup ">
							<div className="popup-header">
								<p className="popup-header__title popup-header__title--ta-left">
									Create transaction
								</p>
							</div>
							<div className="popup-body popup-body--type2">
								<div className={`input ${searchUserError ? 'error' : ''}`}>
									<p className="input__name">User</p>
									<SearchBar
										setDebouncedTerm={setDebouncedTerm}
										debouncedTerm={debouncedTerm}
										searchFieldArray={searchFieldArray}
										searchFieldTextArray={searchFieldTextArray}
										searchField={searchField}
										setSearchField={setSearchField}
										handleClearSearch={handleClearSearch}
									/>
									{searchUserError && (
										<span className="error-message">The user does not exist with this data</span>
									)}
								</div>
								<div className="input ">
									<p className="input__name">User name</p>
									<div className="input-wrapper">
										<input
											className="input-item"
											value={username}
											readOnly
											type="text"
											placeholder=""
										/>
									</div>
								</div>
								<div className="input ">
									<p className="input__name">Currency</p>
									<CDropdown
										options={type === 'crypto' ? assets : assetFiat}
										value={selectWallet}
										onChange={handleWalletSelect}
										disabled={type === 'crypto'}
									/>
								</div>
								<div className="input ">
									<div className="input__header">
										<p className="input__name">Amount</p>
										{type === 'fiat' && (
											<div className="radio-row">
												<div className="radio radio--no-mb">
													<label className="radio__label">
														<span className="radio__text">Mint & Allocate</span>
														<input
															className="hidden"
															type="radio"
															name="one-group"
															checked={withMint}
															onChange={() => setWithMint(true)}
														/>
														<div className="radio__item" />
													</label>
												</div>
												<div className="radio radio--no-mb">
													<label className="radio__label">
														<span className="radio__text">Allocate</span>
														<input
															className="hidden"
															type="radio"
															name="one-group"
															checked={!withMint}
															onChange={() => setWithMint(false)}
														/>
														<div className="radio__item" />
													</label>
												</div>
											</div>
										)}
									</div>
									<div className="input-wrapper">
										<input
											className="input-item input-item--right-icon"
											type="text"
											placeholder="0.00"
											onChange={(e) => {
												const { value } = e.currentTarget;
												if (!/^(\d+(\.\d*)?)?$/.test(value)) {
													return;
												}
												setAmount(e.currentTarget.value);
											}}
											value={amount}
										/>
										<div className="input-info">
											<span className="input-info__currency">
												{selectWallet?.code.toUpperCase() || ''}
											</span>
										</div>
									</div>
								</div>
								<div className={`input ${showComment ? 'active' : ''}`}>
									<div className="input__header">
										<p className="input__name input__name--fw-500">Comment</p>
										<button
											type="button"
											className="input-drop-btn"
											onClick={() => {
												setShowComment((prev) => !prev);
											}}
										>
											<svg
												width="12"
												height="7"
												viewBox="0 0 12 7"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M1.5 1.25L6 5.75L10.5 1.25"
													stroke="#022A36"
													strokeWidth="1.5"
													strokeLinecap="round"
													strokeLinejoin="round"
												/>
											</svg>
										</button>
									</div>
									<div className="input__drop">
										<div className="textarea textarea--mt-16">
											<textarea
												className="textarea__item "
												placeholder="Comment"
												onChange={(e) => setComment(e.currentTarget.value)}
												value={comment}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className="popup-submit popup-submit--type2">
								<button
									onClick={closeModal}
									type="button"
									className="button button--cancel button--br-12 button--full-width "
								>
									Cancel
								</button>
								<button
									type="button"
									onClick={() => setConfirmModal(true)}
									className="button button--check button--br-12 button--full-width "
									disabled={disable}
								>
									Create
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</Popup>
	);
};
