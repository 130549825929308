/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { IExternalMakerItem } from 'redux/reducers/externalMaker/types';
import { getOrderHistoryRequest } from 'redux/reducers/externalMaker/reducer';
import { ExportIcon } from 'assets/img/icons';
import { OrderHistoryFilter } from './OrderHistory/OrderHistoryFilter';
import AddNewPair from './Popups/CreatePopup';
import { OrderHistory } from './OrderHistory';
import { Pairs } from './Pairs';
import EditPair from './Popups/EditPopup';

export const ExternalMakerBot: FC = () => {
	const dispatch = useDispatch();
	const [tabIndex, setTabIndex] = useState(0);
	const [addNewPairPopupOpen, setAddNewPairPopupOpen] = useState(false);
	const [editPairPopupOpen, setEditPairPopupOpen] = useState(false);
	const [editItemData, setEditItemData] = useState<null | IExternalMakerItem>(null);
	const [startDate, setStartDate] = useState<string | null>(null);
	const [endDate, setEndDate] = useState<string | null>(null);
	const history = useHistory();
	const { search, pathname } = useLocation();

	const handleCloseAddNewPair = () => {
		setAddNewPairPopupOpen(false);
	};

	const handleEditPairOpen = (data: IExternalMakerItem) => {
		setEditPairPopupOpen(true);
		setEditItemData(data);
	};

	const handleEditPairClose = () => {
		setEditPairPopupOpen(false);
		setEditItemData(null);
	};

	const handleOrderHistoryExport = () => {
		dispatch(
			getOrderHistoryRequest({
				export: 1,
				start_date: startDate || undefined,
				end_date: endDate || undefined,
			}),
		);
	};

	const onDateSelect = (_startDate: string | null, _endDate: string | null) => {
		setStartDate(_startDate);
		setEndDate(_endDate);
	};

	return (
		<>
			<div className="title-block title-block--admin-managment">
				<p className="title">External</p>
				<div className="admin-managment-buttons">
					{tabIndex === 1 && <OrderHistoryFilter onDateSelect={onDateSelect} />}
					<div className="admin-managment-btn" onClick={() => setAddNewPairPopupOpen(true)}>
						<span className="admin-managment-btn__text">Add New Pair</span>
						<span className="plus-icon plus-icon--action icon-plus-icon" />
					</div>
					{tabIndex === 1 && (
						<button
							type="button"
							className="transaction-export-btn"
							onClick={handleOrderHistoryExport}
						>
							Export order history
							<span className="transaction-export-btn__icon">
								<ExportIcon />
							</span>
						</button>
					)}
				</div>
			</div>
			{addNewPairPopupOpen && <AddNewPair onClose={handleCloseAddNewPair} />}
			{editPairPopupOpen && editItemData && (
				<EditPair onClose={handleEditPairClose} pairData={editItemData} />
			)}
			<Tabs
				className="settings-tabs"
				selectedIndex={tabIndex}
				onSelect={(index) => {
					if (search) {
						history.replace({
							pathname,
							search: undefined,
						});
					}
					setTabIndex(index);
				}}
			>
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Pairs</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Order History</span>
					</Tab>
				</TabList>
				<TabPanel>
					<Pairs onOpenEditPair={handleEditPairOpen} />
				</TabPanel>
				<TabPanel>
					<OrderHistory filterParams={{ startDate, endDate }} />
				</TabPanel>
			</Tabs>
		</>
	);
};
