/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAddExternalMakerPairPayload,
	IDeleteExternalPairPayload,
	IExternalMakerData,
	IExternalMakerPageRequestPayload,
	IExternalMakerStore,
	IExternalMakerUpdatePayload,
	IExternalMakerUpdateResponse,
	IOrderHistoryData,
	IOrderHistoryPageRequestPayload,
} from './types';
// ==========================================:

export const initialState: IExternalMakerStore = {
	externalMakerData: null,
	externalMakerLoader: false,
	orderHistoryData: null,
	orderHistoryLoader: false,
	deleteExternalPairLoader: false,
};

// ==========================================:

const externalMaker = createSlice({
	name: '@@externalMaker',
	initialState,
	reducers: {
		getExternalMakerRequest: (state, action: PayloadAction<IExternalMakerPageRequestPayload>) => {
			const externalMakerRequestState = state;
			externalMakerRequestState.externalMakerLoader = true;
		},
		getExternalMakerRequestSuccess: (state, action: PayloadAction<IExternalMakerData>) => {
			const { payload } = action;
			const externalMakerRequestState = state;

			externalMakerRequestState.externalMakerData = payload;
			externalMakerRequestState.externalMakerLoader = false;
		},
		patchExternalMakerUppdateStatusRequest: (
			state,
			{ payload }: PayloadAction<IExternalMakerUpdatePayload>,
		) => {},
		patchExternalMakerUppdateStatusRequestSuccess: (
			state,
			{ payload }: PayloadAction<IExternalMakerUpdateResponse>,
		) => {
			const responseState = state;

			if (!responseState.externalMakerData) {
				return;
			}

			const result = responseState.externalMakerData?.pairs?.map((item) => {
				return item.id === payload.data.id ? { ...payload.data } : item;
			});

			responseState.externalMakerData.pairs = result;
		},
		addExternalMakerPairRequest: (
			state,
			{ payload }: PayloadAction<IAddExternalMakerPairPayload>,
		) => {},
		addExternalMakerPairRequestSuccess: (
			state,
			{ payload }: PayloadAction<IAddExternalMakerPairPayload>,
		) => {},
		deleteExternalPairRequest: (state, action: PayloadAction<IDeleteExternalPairPayload>) => {
			const deleteState = state;

			deleteState.deleteExternalPairLoader = true;
		},
		deleteExternalPairSuccess: (state) => {
			const deleteState = state;

			deleteState.deleteExternalPairLoader = false;
		},

		getOrderHistoryRequest: (
			state,
			{ payload }: PayloadAction<IOrderHistoryPageRequestPayload>,
		) => {
			const orderHistoryRequestState = state;
			if (payload.export !== 1) {
				orderHistoryRequestState.orderHistoryLoader = true;
			}
		},
		getOrderHistoryRequestSuccess: (state, action: PayloadAction<IOrderHistoryData>) => {
			const { payload } = action;
			const orderHistoryState = state;

			orderHistoryState.orderHistoryData = payload;
			orderHistoryState.orderHistoryLoader = false;
		},
		marketMakerBotInitState: () => initialState,
	},
});
export default externalMaker.reducer;
export const {
	getExternalMakerRequest,
	getExternalMakerRequestSuccess,
	patchExternalMakerUppdateStatusRequest,
	patchExternalMakerUppdateStatusRequestSuccess,
	addExternalMakerPairRequest,
	addExternalMakerPairRequestSuccess,
	deleteExternalPairRequest,
	deleteExternalPairSuccess,
	getOrderHistoryRequest,
	getOrderHistoryRequestSuccess,
} = externalMaker.actions;
