import { FC, useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateTranslatesRequest } from 'redux/reducers/translates/reducer';
import { getCurrentLanguage, getCurrentIndex } from 'redux/reducers/translates/selectors';
import { permissions } from 'services/constants/permissions';
import usePermission from 'services/hooks/usePermission';
import { ITranslatePanelItem } from './types';

const TranslatePanelItem: FC<ITranslatePanelItem> = ({ objKey, value, path }) => {
	const lang = useSelector(getCurrentLanguage);
	const currentIndex = useSelector(getCurrentIndex);
	const dispatch = useDispatch();
	const [active, setActive] = useState(false);
	const [text, setText] = useState(value);
	const [isEditing, setIsEditing] = useState(false);

	const { checkEditable } = usePermission();

	useEffect(() => {
		setText(value);
	}, [value]);

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
		setText(e.target.value);
	};

	const onEdit = () => {
		setIsEditing(true);
		dispatch(
			updateTranslatesRequest({
				index: currentIndex,
				apiParams: {
					path: `${path}.${objKey}`,
					value: text,
					lang,
				},
				onSuccess: () => {
					setIsEditing(false);
					setActive(false);
				},
			}),
		);
	};

	return (
		<div className="translate-item">
			<p className="translate-item__name">{objKey}:</p>
			<div className="translate-item__value">
				{active ? (
					<div className="input-wrapper">
						<input
							type="text"
							className="input-item input-item--height-40"
							value={text}
							onChange={handleInput}
						/>
					</div>
				) : (
					<p className="translate-item__value-text">{value}</p>
				)}
			</div>
			<div className="translate-item__action">
				{active ? (
					<>
						<button
							type="button"
							className="translate-item__action-delete"
							disabled={isEditing || !checkEditable(permissions.MANAGE_TRANSLATIONS)}
							onClick={() => {
								setActive(false);
								setText(value);
							}}
						>
							<svg
								width="12"
								height="12"
								viewBox="0 0 12 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.2002 1.19922L10.8002 10.7992M1.2002 10.7992L10.8002 1.19922"
									stroke="#F44336"
								/>
							</svg>
						</button>
						<button
							type="button"
							className="translate-item__action-check"
							disabled={isEditing}
							onClick={onEdit}
						>
							<svg
								width="12"
								height="9"
								viewBox="0 0 12 9"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M0.799805 4.59844L4.3998 8.19844L11.1998 1.39844"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					</>
				) : (
					<button type="button" onClick={() => setActive(true)}>
						<svg
							width="14"
							height="14"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M0.571289 8.71429L0.217736 8.36073L0.0712891 8.50718V8.71429H0.571289ZM8.28557 1L8.63913 0.646447C8.44387 0.451184 8.12728 0.451184 7.93202 0.646447L8.28557 1ZM12.5713 5.28571L12.9248 5.63927C13.1201 5.44401 13.1201 5.12742 12.9248 4.93216L12.5713 5.28571ZM4.857 13V13.5H5.06411L5.21056 13.3536L4.857 13ZM0.571289 13H0.0712891C0.0712891 13.2761 0.295147 13.5 0.571289 13.5L0.571289 13ZM0.924842 9.06784L8.63913 1.35355L7.93202 0.646447L0.217736 8.36073L0.924842 9.06784ZM7.93202 1.35355L12.2177 5.63927L12.9248 4.93216L8.63913 0.646447L7.93202 1.35355ZM12.2177 4.93216L4.50345 12.6464L5.21056 13.3536L12.9248 5.63927L12.2177 4.93216ZM4.857 12.5H0.571289V13.5H4.857V12.5ZM1.07129 13V8.71429H0.0712891V13H1.07129ZM5.36059 3.92498L9.64631 8.2107L10.3534 7.50359L6.0677 3.21788L5.36059 3.92498ZM6.99986 13.5H12.9999V12.5H6.99986V13.5Z"
								fill="#00DA83"
							/>
						</svg>
					</button>
				)}
			</div>
		</div>
	);
};

export default TranslatePanelItem;
